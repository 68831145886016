import React from "react"
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"


export default function Article(props) {
    const { title, date, meta_description, url, articleImage } = props

    const data = useStaticQuery(graphql`
    query ArticleQuery {
      site {
        siteMetadata {
          siteUrl
          title
          phoneNumber
          faxNumber
          email
          addressRegion
          postalCode
          addressCountry
          streetAddress
          sameAs
          imageUrl
          facebookProfile
          foundingDate
          legalName
        }
      }
    }
  `)

    const schema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "author": data.site.siteMetadata.title,
        "name": title,
        "headline": title,
        "image": articleImage,
        "datePublished": date,
        "dateModified": date,
        "mainEntityOfPage": url,
        "publisher": {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": data.site.siteMetadata.siteUrl,
            "name": data.site.siteMetadata.title,
            "foundingDate": data.site.siteMetadata.foundingDate,
            "legalName" : data.site.siteMetadata.legalName,
            "logo": {
                "@type": "ImageObject",
                "inLanguage": "en-US",
                "url": data.site.siteMetadata.imageUrl,
                "width": 512,
                "height": 512,
                "caption": data.site.siteMetadata.title,
            },
            "sameAs": data.site.siteMetadata.sameAs,
            "address": {
                "@type": "PostalAddress",
                "addressLocality": data.site.siteMetadata.addressLocality,
                "addressRegion": data.site.siteMetadata.addressRegion,
                "postalCode": data.site.siteMetadata.postalCode,
                "addressCountry": data.site.siteMetadata.addressCountry,
                "streetAddress": data.site.siteMetadata.streetAddress
            },
            "email": data.site.siteMetadata.email,
            "faxNumber": data.site.siteMetadata.faxNumber,
            "telephone": data.site.siteMetadata.phoneNumber
        }
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
            {/* add opengraph tags */}
            <meta property="og:type" content="article" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:title" content={title} />
            <meta property="article:author" content={data.site.siteMetadata.title} />
            <meta property="og:description" content={meta_description} />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content={data.site.siteMetadata.title} />
            <meta property="article:publisher" content={data.site.siteMetadata.facebookProfile} />
            <meta property="article:published_time" content={date} />
            <meta property="article:modified_time" content={date} />
            <meta property="og:image" content={articleImage} />
            <meta property="og:image:alt" content={title} />
        </Helmet>
    )
}
