import React from "react"
import './share.scss';
import facebook from "../../images/facebook.svg"
import email from "../../images/email.svg"
import twitter from "../../images/twitter.svg"
import pinterest from "../../images/pinterest.svg"
import linkedin from "../../images/linkedin.svg"


export default function SocialButtons(props) {
    const { pageLocation, pageTitle } = props

    const shareLinks = {
        "facebook": "http://www.facebook.com/sharer.php?u=" + pageLocation,
        "linkedin": "https://www.linkedin.com/sharing/share-offsite/?url=" + pageLocation,
        "email": "mailto:?subject=" + pageTitle + " " + pageLocation,
        "pinterest": "http://pinterest.com/pin/create/button/?url=" + pageLocation + "&description=" + pageTitle,
        "twitter": "https://twitter.com/share?url=" + pageLocation,
    }

    return (
        <div className="share-buttons">
            <p>Share This Page: </p>
            {/* Facebook */}
            <a href={shareLinks.facebook} rel="noreferrer" target="_blank">
                <img src={facebook} alt="Facebook" />
            </a>

            {/* LinkedIn */}
            <a href={shareLinks.linkedin} rel="noreferrer" target="_blank">
                <img src={linkedin} alt="LinkedIn" />
            </a>

            {/* Twitter */}
            <a href={shareLinks.twitter} rel="noreferrer" target="_blank">
                <img src={twitter} alt="Twitter" />
            </a>

            {/* Pinterest */}
            <a href={shareLinks.pinterest} rel="noreferrer" target="_blank">
                <img src={pinterest} alt="Pinterest" />
            </a>

            {/* Email */}
            <a href={shareLinks.email} rel="noreferrer" target="_blank">
                <img src={email} alt="Email" />
            </a>
        </div>
    )
}


