
import React from 'react'
import ValleyLogoLarge from "../../images/ValleyLogoLarge.svg"

import './articleFooter.scss'

const ArticleFooter = (props) => {

    return (
        <div className="articleFooter">
            <div>
                <h2>Lets Connect</h2>
                <h3>Schedule an appointment or give us a call</h3>
                <img src={ValleyLogoLarge} alt="Valley Commercial Service" />

                <p>We’ve been in business since 1937. Throughout the past 80 years, we’ve assisted businesses just like yours with commercial kitchen equipment repair in Cincinnati. Our range of services and products will have your kitchen back in running order in no time.</p>
                {/* <div className="main-button"><a href={content.buttonLink}><strong>{content.buttonText}</strong></a></div> */}
                <div className="main-button"><a href="/contact/"><strong>Contact Us Today</strong></a></div>
                <div className="second-button"><a href="tel:(513)733-1188"><strong>(513) 733-1188</strong></a></div>
            </div>
        </div>
    )
}

export default ArticleFooter
