import React from 'react'
import Helmet from 'react-helmet'

const Head = (props) => {
    const { title, pageDesc, url } = props
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={pageDesc} />
            <link rel="canonical" href={url} />
        </Helmet>
    )
}

export default Head