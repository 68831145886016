import React from "react"
import { graphql } from "gatsby"
import SEOArticle from "../components/SEO/article"
import SEOWebpage from "../components/SEO/webpage"
import Head from "../components/Head"
import Layout from "../components/Layout"
import ShareButtons from "../components/ShareButtons"
import ArticleFooter from "../components/ArticleFooter/index"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogServicesTemplate = ({ data, pageContext, location }) => {
  const post = data.graphCmsServicePosts
  const { slug } = pageContext
  const postUrl = data.site.siteMetadata.siteUrl + '/services/' + slug + "/";

  return (
    <Layout>
      <Head
        title={post.metaTitle}
        pageDesc={post.metaDescription}
        url={postUrl}
      />
      <SEOWebpage
        title={post.metaTitle}
        url={postUrl}
      />
      <SEOArticle
        title={post.title}
        date={post.publishedAt}
        meta_description={post.metaDescription}
        url={postUrl}
        articleImage={post.articleImage.url}
      />
      <article className="services-post" >
        <header>
          <h1>{post.title}</h1>
          <span>{data.site.siteMetadata.title} | {post.publishedAt.slice(0, 10)}</span>
          <hr />
        </header>
        <div className="articleBody">
          <GatsbyImage image={getImage(post.articleImage)} alt={post.title} />
          <section
            dangerouslySetInnerHTML={{ __html: post.body.html }}
            itemProp="articleBody"
          />
          <h2 style={{ textAlign: "center" }}>Brands We Service</h2>
          <div className="brands">
            {post.brandsWeServices.map((brandData) =>
              <a className="brand-link" href={'/brands-we-service/' + brandData.slug + '/'}>
                <div className="inner-text">
                  {brandData.brand}
                </div>
                <GatsbyImage image={getImage(brandData.brandLogo)} alt={brandData.brand} />
              </a>
            )}
          <section>
            <h4>Don't see your brand?</h4>
            <p>Reach out to us today and we would be glad to service any and all brands. We will work directly with the manufacturer and warranty to make sure everything is properly repaired and serviced.</p>
          </section>
          </div>

          <h2 style={{ textAlign: "center" }}>Find {post.title} in Cincinnati</h2>
        </div>
        <ArticleFooter />
        <ShareButtons
          pageLocation={postUrl}
          pageTitle={post.title}
        />
      </article>
    </Layout>
  )
}

export default BlogServicesTemplate

export const pageQuery = graphql`
query ServicePosts($slug: String!) {
  site {
    siteMetadata {
      siteUrl
      title
      imageUrl
    }
  }
  graphCmsServicePosts(slug: {eq: $slug}) {
    id
    metaTitle
    metaDescription
    slug
    title
    body {
      html
    }
    brandsWeServices {
      brand
      brandLogo {
        gatsbyImageData
      }
      slug
    }
    articleImage {
      gatsbyImageData(placeholder: BLURRED)
      url
    }
    publishedAt
  }
}
`
